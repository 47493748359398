.ant-slider .ant-slider-handle::after {
	background-color: transparent;
}

.ant-slider .ant-slider-handle {
	top: 5px;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	border-radius: 50%;
}

.ant-slider .ant-slider-rail, .ant-slider .ant-slider-track {
	border-radius: 10px;
}

.ant-switch.ant-switch-small {
	background: #FFE814;
}

.ant-switch .ant-switch-handle::before {
	border-radius: 50%;
}

.view-mobile-tabs > div > div > div > .ant-tabs-tab {
	width: 25%;
	justify-content: center;
	padding: 0;
	margin: 0 !important;
	border-right: solid 1px rgba(217, 217, 217, 0.1);
}

.view-mobile-tabs > .ant-tabs-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100px;
	background: #161721;
	z-index: 100;
}

.view-mobile-tabs > div > div > .ant-tabs-nav-list {
	width: 100%;
}

.view-mobile-tabs > div > .ant-tabs-nav-operations {
	visibility: hidden !important;
	display: none !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	border: solid 1px #FFE814 !important;
	margin-left: 1px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
	background-color: #FFE814 !important;
}


.slide-in-enter {
	opacity: 0;
	transform: translateX(-100%);
	margin-right: -200px;
}

.slide-in-enter-active {
	transition: transform 300ms ease, opacity 300ms ease-out, margin-right 300ms ease;
	opacity: 1;
	transform: translateX(0);
	margin-right: 0;
}

.slide-in-exit {
	opacity: 1;
	transform: translateX(-100%);
}

.slide-in-exit-active {
	opacity: 0;
	transition: transform 300ms ease, opacity 300ms ease-out;
	transform: translateX(0);
}


.slide-down-enter {
	opacity: 0;
	margin-top: -50px;
}

.slide-down-enter-active {
	transition: margin-top 300ms ease-out, opacity 300ms ease-out;
	opacity: 1;
	margin-top: 0px;
}

.slide-down-exit {
	opacity: 1;
}

.slide-down-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}

.slide-down-enter {
	opacity: 0;
	margin-top: -50px;
}

.slide-down-enter-active {
	transition: margin-top 300ms ease-out, opacity 300ms ease-out;
	opacity: 1;
	margin-top: 0px;
}

.slide-down-exit {
	opacity: 1;
}

.slide-down-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}

.slide-down-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}


.ape-fade-enter {
	opacity: 0;
}

.ape-fade-enter-active {
	transition: opacity 300ms ease-out;
	opacity: 1;
}

.ape-fade-exit {
	opacity: 1;
}

.ape-fade-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}


.ape-slide-enter {
	opacity: 0;
	margin-left: -340px;
	margin-top: -200px;
}

.ape-slide-enter-active {
	transition: margin-left 300ms ease-out, margin-top 300ms ease-out, opacity 300ms ease-out;
	opacity: 1;
	margin-left: 0px;
	margin-top: 0px;
}

.ape-slide-exit {
	opacity: 1;
}

.ape-slide-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}


.ape-flash-enter {
	background-color: #161721 !important;
}

.ape-flash-enter-active {
	transition: background-color 100ms ease-in;
	background-color: #FFE814 !important;
}

.ape-flash-exit {
	background-color: #FFE814 !important;
}

.ape-flash-exit-active {
	background-color: #161721 !important;
	transition: background-color 100ms ease-out;
}

.overlay {
	background: rgba(0, 0, 0, .75);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	border-radius: 8px;
}

.admin-tools {
	border: solid red 1px;
	border-radius: 8px;
	margin-top: 8px;
	padding: 5px;
}

.ant-select-item-option-content {
	justify-content: center;
	align-content: center;
	align-items: center;
}

.user-popover .ant-popover-inner {
	border: solid 1px rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	background: rgba(31, 31, 31, 1);
	padding: 5px;
}

.user-popover .root {
	width: 300px;
	background: rgba(31, 31, 31, 1);
	border-radius: 11px;
	position: relative;
}

.user-popover .user-banner {
	height: 115px;
	padding: 10px;
	background-size: contain !important;
	background-repeat: no-repeat !important;
}

.user-popover .tier-1 .user-banner {
	background: url(/public/img/profile-bg-1.png);
}

.user-popover .tier-2 .user-banner {
	background: url(/public/img/profile-bg-2.png);
}

.user-popover .tier-3 .user-banner {
	background: url(/public/img/profile-bg-3.png);
}

.user-popover .tier-4 .user-banner {
	background: url(/public/img/profile-bg-4.png);
}

.user-popover .user-avatar {
	border-radius: 50%;
	border: solid 2px rgba(93, 93, 93, 1);
	background: rgba(93, 93, 93, 1) ;
	width: 64px;
	height: 64px;
}

.user-popover .hero-border {
	position: relative;
	margin-top: -50px !important;
	background: rgba(43, 43, 43, 1);
	border: 1px solid rgba(60, 60, 60, 1);
	margin: 10px;
	border-radius: 10px;
	right: 0px;
	left: 0px;
	bottom: 0px;
}

.user-stats {
	display: flex;
	background: rgba(43, 43, 43, 1);
	flex-direction: column;
	border-radius: 10px;
	padding: 10px;
}

.user-stats.trans {
	background: none;
	padding-top: 0px
}

.user-popover .free-champ .hero-border {
	background: linear-gradient(180deg, #FFE814 0%, #2B2B2B 100%);
	padding: 1px;
}

.user-stats hr {
	border: 1px solid rgba(217, 217, 217, 0.1);
	width: 100%;
}

.user-stats .section {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	margin: 5px 0;
}

.user-stats .stat, .user-stat {
	border-radius: 10px;
	background: rgba(55, 55, 55, 1);
	padding: 5px;
	margin-bottom: 5px;
	margin-right: 5px;
	justify-content: center;
	align-content: center;
}

.user-stats .stat .ant-typography, .user-stat .ant-typography {
	font-size: 12px;
}

.user-popover .award-champ .user-stats .stat.awards {
	background: rgba(255, 232, 20, 0.2);
	border: 1px solid rgba(255, 232, 20, 0.86)
}

.user-nickname {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	margin: 0;
	margin-bottom: 0 !important;
}

.tier-4 .user-nickname {
	color: rgba(255, 232, 20, 1);
}

.user-address {
	font-family: Inter;
	font-size: 11px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
}

.user-popover .ant-spin {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-popover .ant-spin-dot-item {
	background: #fff;
}

.ant-slider-mark {
	margin-top: -10px;
}

.ant-slider-dot {
	margin-top: 5px;
}

.ant-pagination-item-active {
	border: solid 1px #FFE814 !important;
}

.ant-pagination-item-active > a {
	color: #fff !important;
}

.table-card .ant-card-body {
	padding: 0;
}

.refer-page-header {
	font-family: Inter;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	display: block;
	padding-bottom: 20px;
	color: #fff;
}

.card-focal {
	font-family: Inter;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	text-align: left;
	color: #fff;
	margin-bottom: 10px;
}

.card-vertical .ant-card-head, .table-card .ant-card-head {
	border-bottom: none;
}

.card-vertical .ant-card-head .ant-card-head-title, .table-card .ant-card-head .ant-card-head-title {
	text-align: center;
}

.card-vertical .ant-card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.view-slippage {
	width: 100%;
	padding: 10px;
}

.view-slippage span {
	display: block;
}

.view-slippage > .ant-typography {
	font-size: 18px;
	font-family: Inter;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
}

.referral-link {
	width: 100%;
	border: solid 1px rgba(255, 231, 16, 0.56);
	border-radius: 17px;
	height: 35px;
	background-color: rgba(22,23,23,0.66);
	padding: 0px 20px 0px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
}

.profile-tokens {
	flex-wrap: wrap;
}

.profile-tokens > div, .profile-tokens > span {
	margin-inline-start: 0 !important;
	margin: 5px;
}

.grid-pager li.ant-pagination-simple-pager {
	font-size: 0;
}

.grid-pager li.ant-pagination-simple-pager span {
	display: none;
}